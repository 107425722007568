<template>
  <div class="tinymce-box">
    <Editor v-model="contentValue" :init="init" :disabled="disabled" />
    <div class="shade" v-if="isShade"></div>
  </div>
</template>

<script>
//引入tinymce编辑器
import Editor from "@tinymce/tinymce-vue"
//引入node_modules里的tinymce相关文件文件
import tinymce from "tinymce/tinymce" //tinymce默认hidden，不引入则不显示编辑器
import "tinymce/themes/silver" //编辑器主题，不引入则报错
import "tinymce/icons/default" //引入编辑器图标icon，不引入则不显示对应图标
// 引入编辑器插件（基本免费插件都在这儿了）
import "tinymce/icons/default/icons"
import "tinymce/plugins/advlist" //高级列表
import "tinymce/plugins/anchor" //锚点
import "tinymce/plugins/autolink" //自动链接
import "tinymce/plugins/autoresize" //编辑器高度自适应,注：plugins里引入此插件时，Init里设置的height将失效
import "tinymce/plugins/autosave" //自动存稿
import "tinymce/plugins/charmap" //特殊字符
import "tinymce/plugins/code" //编辑源码
import "tinymce/plugins/codesample" //代码示例
import "tinymce/plugins/directionality" //文字方向
import "tinymce/plugins/emoticons" //表情
import "tinymce/plugins/fullpage" //文档属性
import "tinymce/plugins/fullscreen" //全屏
import "tinymce/plugins/help" //帮助
import "tinymce/plugins/hr" //水平分割线
import "tinymce/plugins/importcss" //引入css
import "tinymce/plugins/insertdatetime" //插入日期时间
import "tinymce/plugins/link" //超链接
import "tinymce/plugins/lists" //列表插件
import "tinymce/plugins/media" //插入编辑媒体
import "tinymce/plugins/image" // 插入图片
import "tinymce/plugins/nonbreaking" //插入不间断空格
import "tinymce/plugins/pagebreak" //插入分页符
import "tinymce/plugins/paste" //粘贴插件
import "tinymce/plugins/preview" //预览
import "tinymce/plugins/print" //打印
import "tinymce/plugins/quickbars" //快速工具栏
import "tinymce/plugins/save" //保存
import "tinymce/plugins/searchreplace" //查找替换
// import 'tinymce/plugins/spellchecker'  //拼写检查，未加入汉化，不建议使用
import "tinymce/plugins/tabfocus" //切入切出，按tab键切出编辑器，切入页面其他输入框中
import "tinymce/plugins/table" //表格
import "tinymce/plugins/template" //内容模板
import "tinymce/plugins/textcolor" //文字颜色
import "tinymce/plugins/textpattern" //快速排版
import "tinymce/plugins/toc" //目录生成器
import "tinymce/plugins/visualblocks" //显示元素范围
import "tinymce/plugins/visualchars" //显示不可见字符
import "tinymce/plugins/wordcount" //字数统计

import { getToken } from "@/utils/auth" // 本地工具（上传）
import axios from "axios"
import md5 from "js-md5"

export default {
  name: "TEditor",
  components: {
    Editor,
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    plugins: {
      type: [String, Array],
      default:
        "link lists image code table wordcount media fullscreen preview paste",
    },
    toolbar: {
      type: [String, Array],
      default:
        "fontselect | bold italic underline strikethrough  | fontsizeselect | image media forecolor backcolor | alignleft aligncenter alignright alignjustify | bullist numlist | outdent indent blockquote | code | removeformat",
    },
    height: {
      type: [Number, String],
      required: false,
      default: "360",
    },
    // 问题Id
    qid: {
      type: [Number, String],
      default: -1,
    },
    // 答案Id
    aid: {
      type: [Number, String],
      default: -1,
    },
  },
  data () {
    return {
      init: {
        language_url: "langs/zh_CN.js", //引入语言包文件
        language: "zh_CN", //语言类型
        skin_url: "skins/ui/oxide", //皮肤：浅色
        plugins: this.plugins, //插件配置
        toolbar: this.toolbar, //工具栏配置，设为false则隐藏
        menubar: false,
        // menubar: 'file edit insert view format table',  //菜单栏配置，设为false则隐藏，不配置则默认显示全部菜单，也可自定义配置--查看 http://tinymce.ax-z.cn/configure/editor-appearance.php --搜索“自定义菜单”
        fontsize_formats:
          "12px 14px 16px 18px 20px 22px 24px 28px 32px 36px 48px 56px 72px", //字体大小
        font_formats:
          "微软雅黑=Microsoft YaHei,Helvetica Neue,PingFang SC,sans-serif;苹果苹方=PingFang SC,Microsoft YaHei,sans-serif;宋体=simsun,serif;仿宋体=FangSong,serif;黑体=SimHei,sans-serif;Arial=arial,helvetica,sans-serif;Arial Black=arial black,avant garde;Book Antiqua=book antiqua,palatino;", //字体样式
        lineheight_formats: "0.5 0.8 1 1.2 1.5 1.75 2 2.5 3 4 5", //行高配置，也可配置成"12px 14px 16px 20px"这种形式
        height: this.height, //注：引入autoresize插件时，此属性失效
        placeholder: "请输入",
        doctype: "<!DOCTYPE html>",
        toolbar_mode: "sliding",
        branding: false, //tiny技术支持信息是否显示
        resize: "both", //编辑器宽高是否可变，false-否,true-高可变，'both'-宽高均可，注意引号
        statusbar: false, //最下方的元素路径和字数统计那一栏是否显示
        elementpath: false, //元素路径是否显示
        content_style: "img {max-width:100%;}", //直接自定义可编辑区域的css样式
        // content_css: '/tinycontent.css',  //以css文件方式自定义可编辑区域的css样式，css文件需自己创建并引入
        // images_upload_url: '/apib/api-upload/uploadimg',  //后端处理程序的url，建议直接自定义上传函数image_upload_handler，这个就可以不用了
        // images_upload_base_path: '/demo',  //相对基本路径--关于图片上传建议查看--http://tinymce.ax-z.cn/general/upload-images.php
        // paste_data_images: false, //图片是否可粘贴
        // images_upload_handler: (blobInfo, success, failure) => {
        //   this.$emit('handleImgUpload', blobInfo, success, failure)
        // },
        // images_upload_handler: async function (blobInfo, success, failure) {
        //   let form = new FormData();
        //   form.append("file", blobInfo.blob(), blobInfo.filename());
        //   try {
        //     // let { data } = await upload(form);
        //     // console.log(data);
        //     // success(data[0].url);
        //   } catch (error) {
        //     //console.log(error);
        //     // alert("上传图片失败");
        //     // success("");
        //   }
        // },
        file_picker_types: "media, image",
        file_picker_callback: (callback, value, meta) => {
          const that = this
          that.callback_fn = callback
          let filetype = ""
          let materialType = ""
          switch (meta.filetype) {
            case "image":
              filetype = ".jpg, .jpeg, .png, .gif"
              materialType = "1"
              break
            case "media":
              filetype = ".mp3, .mp4"
              break
            default:
          }
          let input = document.createElement("input")
          input.setAttribute("type", "file")
          input.setAttribute("accept", filetype)
          input.onchange = function () {
            let file = this.files[0]
            if (file.type == 'video/mp4' || file.type.indexOf('video') != -1) {
              materialType = '3'
            } else if (file.type == 'audio/mpeg' || file.type == 'audio/mp3' || file.type.indexOf('audio') != -1) {
              materialType = '2'
            }
            that.uploadFile(file, materialType) // 上传文件
          }
          input.click()
        },
      },
      contentValue: this.value,
      // 图片上传参数
      upload_img: false,
      imgUrl: "",
      callback_fn: null,
      // 视频上传参数
      upload_video: false,
      videoUrl: "",
      loading: null,
      // 上传配置
      headers: {},
      schoolid: null,
      isShade: false,
    }
  },
  watch: {
    value (newValue) {
      // debugger
      this.contentValue = newValue
    },
    // 传值给父组件
    contentValue (newValue) {
      //原来的方式
      // this.$emit("setInput", newValue);
      let key = { qid: this.qid, aid: this.aid } // 富文本对应的ID，方便对应回复的问题
      this.$emit("setInput", newValue, key)
    },
    // 上传的图片
    upload_img: {
      handler (newVal) {
        if (newVal) {
          //console.log("回显",this.callback_fn);
          this.callback_fn(this.imgUrl)
          this.upload_img = false
        }
      },
      immediate: true,
      deep: true,
    },
    // 上传的视频
    upload_video: {
      handler (newVal) {
        if (newVal) {
          this.callback_fn(this.videoUrl)
          this.upload_video = false
        }
      },
      immediate: true,
      deep: true,
    },
  },
  mounted () {
    this.schoolid = sessionStorage.getItem("schoolid") * 1
    this.headers["Authorization"] = "Bearer " + getToken()
    this.headers["Content-Type"] = "multipart/form-data"
    // this.headers["fromtype"] = 2;
    // this.headers["identifier"] = 2;
    this.headers["schoolid"] = this.schoolid || 1

    tinymce.init({})
    this.setHeaders() // 配置表头
  },
  methods: {
    setHeaders () {
      const date = new Date()
      const c_time = Math.trunc(date.getTime() / 1000)
      const sign_time = c_time.toString()
      const app_version = "v1.0"
      this.headers["app-version"] = app_version
      this.headers["request-time"] = c_time
      this.headers["request-sign"] = md5(md5(sign_time) + app_version)
    },
    /**开启全屏loading **/
    startLoading () {
      this.loading = this.$loading({
        lock: true,
        text: "文件上传中...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.8)",
      })
    },
    /**关闭loading**/
    closeLoading () {
      this.loading.close()
    },
    async checkSize (file) {
      const isSize = file.size / 1024 / 1024 < 50
      if (!isSize) {
        this.$message.error("上传文件大小不能超过 50MB!")
        return false
      }
      // return isSize;
    },
    async uploadFile (file, materialType) {
      this.isShade = true
      let myfile = file
      this.startLoading()
      this.checkSize(file)
      let formData = new FormData()
      formData.append("file", file)
      formData.append("is_unzip", 0)
      await axios({
        url: process.env.VUE_APP_BASE_API + "/common/upload-file/upload",
        method: "post",
        headers: this.headers,
        data: formData,
      }).then((res) => {
        this.isShade = false
        if (res.data.code === 0) {
          //console.log("上传返回的文件信息",res.data.data);
          // 分类
          if (materialType == 1) {
            this.upload_img = true
            this.imgUrl = res.data.data[0].url
          } else {
            this.upload_video = true
            this.videoUrl = res.data.data[0].url
          }
        } else {
          this.$message.error(res.data.message)
        }
        this.closeLoading()
      })
        .catch((err) => {
          this.closeLoading()
        })
    },
  },
};
</script>

<style lang="scss" scoped>
.tox-notifications-container {
  display: none;
}

.tox-tinymce-aux {
  z-index: 5000 !important;
}

.shade {
  background-color: #00000020;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 99999999;
}
</style>

